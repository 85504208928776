import { interpolate, splitPathString } from "flubber";

//split any multi-shape paths into individual paths
export const splitAndNormalizePaths = function (svgPaths) {
    const realPaths = [];

    svgPaths.forEach((pathElement) => {
        const pathData = pathElement.getAttribute("d");
        const splitPathsData = splitPathString(pathData); // Assume this returns an array of path strings

        splitPathsData.forEach((singlePathData) => {
            const newPathElement = document.createElementNS(
                "http://www.w3.org/2000/svg",
                "path"
            );
            newPathElement.setAttribute("d", singlePathData);
            realPaths.push(newPathElement);
        });
    });

    return realPaths;
}

//replace the original paths in the SVG with the new real paths
export const replacePathsInSVG = function (svgElement, newPaths) {
    // Remove all existing path elements from the SVG
    Array.from(svgElement.querySelectorAll("path")).forEach((path: any) => {
        path.parentNode.removeChild(path);
    });

    // Append the new path elements to the SVG
    newPaths.forEach((newPath) => {
        svgElement.appendChild(newPath);
    });
}

export const createInterpolators = function (svg1Paths, svg2Paths) {
    const interpolators = [];

    svg1Paths.forEach((path, index) => {
        const fromPathData = path.getAttribute("d") || "";
        const toPathData = svg2Paths[index].getAttribute("d") || "";
        interpolators.push(interpolate(fromPathData, toPathData));
    });
    return interpolators;
}