// Add all components for theme here (will be accessible from "_components")
// If theres a chance a component will not be loaded, it should be lazy loaded

export { default as LinkLogic } from './LinkLogic/index.vue';
export { default as LinkButton } from './LinkButton/index.vue';
export const PillButton = () => import("./PillButton/index.vue");
export { default as CTAButton } from "./CTAButton/index.vue";
export { default as ImageWithVideo } from './ImageWithVideo/index.vue';
export { default as SVGMorph } from './SVGMorph/index.vue';
export * as morphUtils from './SVGMorph/morph-utils';
export const SliderButtons = () => import("./SliderButtons/index.vue");
export const SpinningIcon = () => import('./SpinningIcon/index.vue');

export const MobAccordion = () => import('./MobAccordion/index.vue');
export { AccordionProps } from './MobAccordion/types';
export const MobButton = () => import('./MobButton/index.vue');



export { default as Header } from "./Header/index.vue";
export { default as FooterEmail } from "./FooterEmail/index.vue";
export { default as Footer } from "./Footer/index.vue";
export const WindowBlocks = () => import("./WindowBlocks/index.vue");

export { default as Sidebar } from "./Sidebar/index.vue";
export { SideBarProps } from './Sidebar/types';

export const DynamicContent = () => import("./DynamicContent/index.vue");
export const TabPanel = () => import("./TabPanel/index.vue");
export const CookieDialog = () => import("./CookieDialog/index.vue");


export const PageAnimationTrigger = () => import('./PageAnimation/TriggerMixin.vue');
export const ComplexAnimationHandler = () => import('./PageAnimation/ComplexAnimationHandler.vue');
