import { WPImage } from "../src/components/SmartImage/src/types";
import {
  MetaTypes,
  SeoSettings,
  SocialMediaSeo,
  SeoFromWPPage,
  SeoGoogleAnalytics,
  MetaInfoType,
  MetaInfoScriptType,
  MetaInfo,
  DynamicPageSEO,
} from "./seo";
import {
  FontModes,
  FontSettings,
  FontSettingsDetail,
  FontSettingsType,
} from "./fonts";

export { ACTIONS, MUTATIONS } from "../src/store/src/types";
export {
  WPImage,
  MetaTypes,
  SeoSettings,
  SocialMediaSeo,
  SeoFromWPPage,
  SeoGoogleAnalytics,
  MetaInfoType,
  MetaInfoScriptType,
  FontModes,
  FontSettings,
  FontSettingsDetail,
  FontSettingsType,
  MetaInfo,
};

export type WPPostType = {
  ID: number;
  comment_count: string;
  comment_status: string;
  filter: string;
  guid: string;
  menu_order: number;
  ping_status: string;
  pinged: string;
  post_author: string;
  post_content: string;
  post_content_filtered: string;
  post_date: string;
  post_date_gmt: string;
  post_excerpt: string;
  post_mime_type: string;
  post_modified: string;
  post_modified_gmt: string;
  post_name: string;
  post_parent: number;
  post_password: string;
  post_status: string;
  post_title: string;
  post_type: string;
  to_ping: string;
};

export type WPTagType = {
  count: number;
  description: string;
  filter: string;
  name: string;
  parent: number;
  slug: string;
  taxonomy: string;
  term_group: number;
  term_id: number;
  term_taxonomy_id: number;
}

// Utils

export type DebugElementOutput = {
  debugging: HTMLElement;
  top: number;
  bottom: number;
  height: number;
  classList: DOMTokenList;
  transition: string;
  transform: string;
};

export type AnyObjectOfStrings = { [key: string]: string };

export enum ApiRoutes {
  Static = "__STATIC__",
  DynamicPage = "__DYNAMIC__",
  Draft = "__DRAFT__",
  HomePage = "__HOME_PAGE__",
  Settings = "__SETTINGS__",
  Search = "__SEARCH__",
  QueryPost = "__QUERY__",
  Custom = "__CUSTOM__"
}


export enum RouterPageNames {
  HOME = "home",
  PAGE = "page",
  POST = "post",
  FOUR_O_FOUR = "404",
  CUSTOM = 'custom',
  PAGE_OR_POST = "PAGE_OR_POST"
}

export type DynamicPagePropsBase = {
  acf: {
    seo: DynamicPageSEO;
  };
  category: string | null;
  lang: string | null;
  name: string;
  post_data: WPPostType;
  post_type: string;
  status: number;
  translations: { [key: string]: number } | null;
  tags: WPTagType[];
};

export type MainSettingsBase = {
  globalSettings: {
    font_settings: FontSettings;
  };
  seo: SeoSettings;
};

export type SearchResult = {
  search: string;
  posts: any[];
};

export const EMPTY_SEARCH = {
  posts: [],
  search: "",
};

export type WPCategoryType = {
  term_id: number,
  name: string,
  slug: string,
  term_group: number,
  term_taxonomy_id: number,
  taxonomy: string,
  description: string,
  parent: number,
  count: number,
  filter: string,
  cat_ID: number,
  category_count: number,
  category_description: string,
  cat_name: string,
  category_nicename: string,
  category_parent: number
}

export enum THEME_LINK_TYPE {
  PAGE_LINK = "pageLink",
  THEME_LINK = "themeLink",
  EXTERNAL_URL = 'externalURL',
  DOWNLOAD = 'download'
}

export enum THEME_LINKS {
  ALL_ARTICLES = "allArticles",
  HOME_PAGE = "homePage",
  SEARCH = 'search'
}

export type ThemeLink = {
  label: string;
} & ThemeLinkLogic;

export type ThemeLinkLogic = {
  type: THEME_LINK_TYPE;
  theme_link?: THEME_LINKS;
  page_link?: string;
  external_url?: string;
  file_download?: string;
  query?: string;
}

export * from "@app/types";
