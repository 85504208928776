export default {
  project: {
    name: "STUDIO-BAUHAUS",
    version: process.env.npm_package_project_version,

    // These values will be injected into style.css on export
    themeURL: "http://www.studiobau.haus/",
    author: "studio bauhaus",
    authorURL: "http://www.studiobau.haus/",
    description: "SB Boilerplate",
    license: "Private",
    tags: ["sbboilerplate"],
  },

  options: {
    enableResize: true,
    enableFontLoader: true,
    enableRaf: true,
    enableBlurFocus: true,
    enablePerf: false, // Run performance test on load
  },

  enablePasswordProtection: false,
  password: 'bauhaus@2023',

  enableCaching: true,

  // By default will use theme (for mobile browser) from WP settings - certain themes will change color dynamically
  enableStaticTheme: true,

  // Message to show in console log on production
  consoleMessage: [
    "\nbuilt by\n\n%cstudio\nbauhaus%c\n\nhttps://studiobau.haus/\n",
    "font-weight: 300; line-height: 0.8em; text-transform: uppercase; font-size: 20px; font-family: Helvetica, Arial, sans-serif",
    "font-family: Helvetica, Arial, sans-serif",
  ],

  // Should users be pushed to 404 if page doesn't exist?
  enable404Push: true,
};

// Add theme colors here, they are automatically imported into boilerplate colors module
export enum ThemeColors {
  Black = '#000000'
}

// Event Bus channels specific to theme go here
export enum EventBusChannelsThemeSpecific {
  FooterPosition = "footer:position",
  TriggerComplexAnimation = "trigger:animation:complex",
  CheckComplexAnimation = "check:animation:complex",
  CheckReturnComplexAnimation = "return:animation:complex",
  EndComplexAnimation = "end:animation:complex",
  EndIntroAnimation = "end:animation:page:intro",
  SidebarDetected = "sidebar:detected",
  SidebarHidden = "sidebar:hidden"
}
