
import {
  DevTools,
  Header,
  Footer,
  CookieDialog,
  ComplexAnimationHandler,
} from "_components";
import { Route } from "vue-router/types/router";
import { Component, Mixins, Ref } from "vue-property-decorator";
import { createApp } from "_mixins";

@Component({
  components: {
    DevTools,
    Header,
    Footer,
    CookieDialog,
    ComplexAnimationHandler,
  },
})
export default class Boilerplate extends Mixins(createApp) {
  @Ref() pageRef;
  $projectName: string;

  onPageChange(to: Route, from: Route, next: () => void): void {
    // Trigger page animation from here
    next();
  }

  handleEnter() {}

  get footerSettings() {
    return this.settings ? this.settings.footer : null;
  }
}
