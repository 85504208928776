
import { Vue, Component, Prop, Ref } from "vue-property-decorator";
import { SmartText } from "_components";
import { ThemeLink, ThemeLinkLogic, THEME_LINKS,
  THEME_LINK_TYPE, } from "_types";
import LinkLogic from "../LinkLogic/index.vue";
import { createModifierClass, getRouterLink } from "_utils";

@Component({
  name: "CTAButton",
  components: { LinkLogic, SmartText },
})
export default class CTAButton extends Vue {
  @Ref() linkLogic: LinkLogic;
  @Prop({ type: String }) label: string;
  @Prop() link: ThemeLink | ThemeLinkLogic;
  @Prop({ default: 'hero-navigation' }) textType: string;
  @Prop() bold: boolean;

  rootClass = "c-cta-button";
  isMounted = false;

  get wrapperClasses() {
    return [
      this.rootClass,
        ...createModifierClass(
          this.rootClass,
          this.isOnCurrentPage,
          "isOnCurrentPage"
        ),
      ];
  }

  get url() {
    const { link } = this;

    if (!link) return;
    switch (link.type) {
      case THEME_LINK_TYPE.PAGE_LINK:
        return link.page_link && getRouterLink(link.page_link);
      case THEME_LINK_TYPE.THEME_LINK:
        switch (link.theme_link) {
          case THEME_LINKS.HOME_PAGE:
            return "/";
          case THEME_LINKS.ALL_ARTICLES:
            return "/posts/";
          case THEME_LINKS.SEARCH:
            return `/search?query=${link.query}`;
          default:
            return "";
        }
      case THEME_LINK_TYPE.DOWNLOAD:
        return link.file_download;
      case THEME_LINK_TYPE.EXTERNAL_URL:
        return link.external_url;
      default:
        return "";
    }
  }

  get isOnCurrentPage() {
    return this.url ? this.$route.path === this.url : false;
  }

  mounted() {
    this.isMounted = true;
  }

  click() {
    this.$emit("click");
  }
}
