
import { Mixins, Component, Prop, Ref } from "vue-property-decorator";
import { SmartImage } from "_components";
import { ImageWithVideoProps } from "./types";
import { config } from "_core";
import { onView } from "_mixins";

@Component({
  name: "ImageWithVideo",
  components: { SmartImage },
})
export default class ImageWithVideo extends Mixins(onView) {
  @Prop({ required: true }) data: ImageWithVideoProps;
  @Prop({ default: false }) enableVideoAutoplay: boolean;
  @Prop({ default: false }) isInsideTransition: boolean;
  @Prop({ default: false }) isTabPanel: boolean;
  @Prop({ type: Boolean, default: false }) enableVideoAutoplayOnMobile: boolean;
  @Ref() imageRef: SmartImage;
  @Ref() videoRef: HTMLVideoElement;

  rootClass = "c-image-with-video";
  showVideo = false;

  videoLoaded = false; // Flag to check if video is loaded

  onVideoLoaded() {
    this.videoLoaded = true; // Set flag to true when video metadata is loaded
    this.checkVideoPlayConditions();
  }

  checkVideoPlayConditions() {
    if (
      this.videoLoaded &&
      (!this.isMobile &&
      this.enableVideoAutoplay &&
      this.enableVideo) ||
      (this.isMobile && this.enableVideoAutoplayOnMobile)
    ) {
      (this.imageRef.$el as HTMLElement).style.opacity = "0";
      this.videoRef.play();
    } else {
      (this.imageRef.$el as HTMLElement).style.opacity = "1";
      this.videoRef.pause();
    }
  }

  get image() {
    return (
      this.data &&
      this.data.image && {
        image: this.data.image,
        lazyload: true,
        fit: "contain",
        fullHeight: true,
      }
    );
  }

  get enableVideo() {
    return this.data?.enable_video;
  }

  get video() {
    return this.data?.video?.url;
  }

  get isMobile() {
    return window?.innerWidth <= config.mobBreakpoint;
  }

  mounted() {
    if (this.videoRef) {
      this.videoRef.addEventListener("loadedmetadata", this.onVideoLoaded);
    }
    this.$nextTick(() => {
      if (!this.isInsideTransition && this.isTabPanel) return;
      if (
        this.videoRef &&
        (!this.isMobile &&
        this.enableVideoAutoplay &&
        this.enableVideo) ||
          (this.isMobile && this.enableVideoAutoplayOnMobile)
      ) {
        (this.imageRef.$el as HTMLElement).style.opacity = "0";
        this.videoRef.play();
      } else {
        (this.imageRef.$el as HTMLElement).style.opacity = "1";
        this.videoRef.pause();
      }
    })
  }

  onView(inView) {
    this.$nextTick(() => {
      if (!this.videoRef || !this.videoLoaded) return;
      if (
        inView &&
        (!this.isMobile &&
        this.enableVideoAutoplay &&
        this.enableVideo) ||
        (this.isMobile && this.enableVideoAutoplayOnMobile)
      ) {
        (this.imageRef.$el as HTMLElement).style.opacity = "0";
        this.videoRef.play();
      } else {
        (this.imageRef.$el as HTMLElement).style.opacity = "1";
        this.videoRef.pause();
      }
    });
  }

  handleHover() {
    if (!this.enableVideo || this.isMobile) return;

    (this.imageRef.$el as HTMLElement).style.opacity = "0";
    this.videoRef.play();
  }

  handleMouseleave() {
    if (this.enableVideoAutoplay) return;
    if (!this.enableVideo || this.isMobile) return;
    this.videoRef.pause();
    this.videoRef.currentTime = 0;
    (this.imageRef.$el as HTMLElement).style.opacity = "1";
  }
}
