
import { Vue, Component, Ref, Prop, Watch } from "vue-property-decorator";
import { SmartSVG } from "_components";
import { GSAP } from "_core";

import {
  splitAndNormalizePaths,
  replacePathsInSVG,
  createInterpolators,
} from "./morph-utils";

@Component({
  name: "SVGMorph",
  components: { SmartSVG },
})
export default class SVGMorph extends Vue {
  @Prop() settings: { icons: [string, string]; selected: number };
  @Ref() firstIcon: SmartSVG;
  @Ref() secondIcon: SmartSVG;

  rootClass = "c-svg-morph";
  loadCounter = 0;
  timeline: gsap.core.Timeline;
  isInitialized = false;

  worker: Worker;

  handleLoad() {
    this.loadCounter++;
    // Only proceed if both SVGs are loaded
    if (this.loadCounter === 2) {
      // Emit an event to indicate readiness for external trigger
      this.$emit("readyForInit");
    }
  }

  public triggerInit() {
    if (!this.isInitialized) {
      this.init();
      this.isInitialized = true;
    }
  }

  init() {
    const svg1Paths = Array.from(
      this.firstIcon.$el.querySelectorAll("path")
    ) as SVGPathElement[];
    const svg2Paths = Array.from(
      this.secondIcon.$el.querySelectorAll("path")
    ) as SVGPathElement[];

    if (!svg1Paths.length || !svg2Paths.length) return;

    // Split any multi-shape paths into individual paths
    const svg1RealPaths = splitAndNormalizePaths(svg1Paths);
    const svg2RealPaths = splitAndNormalizePaths(svg2Paths);

    // Replace the original paths in the SVG with the new real paths
    replacePathsInSVG(this.firstIcon.$el, svg1RealPaths);

    this.$nextTick(() => {
      if (window.Worker && !process.env.HMR) {
        this.worker = new Worker(new URL("./worker.js", import.meta.url), {
          type: "module",
        });

        this.worker.onmessage = (e) => {
          if (e.data.error) {
            console.error("Error in worker:", e.data.error);
          } else if (e.data.shape) {
            svg1RealPaths[e.data.index].setAttribute("d", e.data.shape);
            this.firstIcon?.$el.setAttribute(
              "viewBox",
              e.data.interpolatedViewBox.join(" ")
            );
          }
        };

        svg1RealPaths.forEach((path, index) => {
          const fromPathData = path.getAttribute("d") || "";
          const toPathData = svg2RealPaths[index].getAttribute("d") || "";
          this.worker.postMessage({
            type: "createInterpolator",
            startShape: fromPathData,
            endShape: toPathData,
            index,
          });
        });

        this.setupTimeline(svg1RealPaths);
      } else {
        const interpolators = createInterpolators(svg1RealPaths, svg2RealPaths);
        this.setupTimeline(svg1RealPaths, interpolators);
      }
      this.startAnimationToIcon(this.settings.selected);
    });
  }

  setupTimeline(svg1Paths, interpolators: any = false) {
    // Extract the viewBox values for both SVGs
    const svg1Element = this.firstIcon.$el as SVGSVGElement;
    const svg2Element = this.secondIcon.$el as SVGSVGElement;
    const viewBox1 = svg1Element.getAttribute("viewBox");
    const viewBox2 = svg2Element.getAttribute("viewBox");

    if (!viewBox1 || !viewBox2) {
      console.error("Failed to obtain viewBox values");
      return;
    }

    // Split the viewBox values into arrays of numbers
    const viewBox1Values = viewBox1.split(" ").map(Number);
    const viewBox2Values = viewBox2.split(" ").map(Number);

    this.timeline = GSAP.timeline({
      paused: true,
      onUpdate: () => {
        const t = this.timeline.progress();

        // Interpolate the viewBox values
        const interpolatedViewBox = viewBox1Values.map((value, index) =>
          GSAP.utils.interpolate(value, viewBox2Values[index], t)
        );

        if (interpolators) {
          // Update the viewBox attribute of the first SVG element
          svg1Element.setAttribute("viewBox", interpolatedViewBox.join(" "));
        }

        // Update the path data of the first SVG element
        svg1Paths.forEach((path, index) => {
          if (interpolators) {
            const interpolatedPath = interpolators[index](t);
            path.setAttribute("d", interpolatedPath);
          } else {
            this.worker.postMessage({
              type: "getInterpolatedShape",
              progress: t,
              index,
              interpolatedViewBox,
            });
          }
        });
      },
    }).to({}, 1, {});
  }

  @Watch("settings.selected")
  onSelectedChange(newValue: number) {
    if (!this.isInitialized) return;
    this.startAnimationToIcon(newValue);
  }

  startAnimationToIcon(iconIndex: number) {
    if (!this.timeline) return;
    const targetProgress = iconIndex === 0 ? 0 : 1;
    if (this.timeline.progress() !== targetProgress) {
      this.timeline.tweenTo(targetProgress, {
        duration: 0.5,
        ease: "circ.inOut",
      });
    }
  }
}
