/*
    Any functions placed here will be appended to '_utils'
*/

import { ThemeLink, THEME_LINKS, THEME_LINK_TYPE } from "_types";
import { config } from '_core';
import { v4 } from 'uuid'

/** @module ThemeUtils **/

export const getRouterLink = function (url: string) {
  return url.startsWith(config.baseUrl) ? url.slice(config.baseUrl.length) : url;
};

export const generateID = function () {
  return v4();
};

export const transform = function (element: HTMLElement, x = 0, y = 0, scale = 1) {
  if (element && element.style)
    element.style.transform = `translate3d(${x}px, ${y}px, 0px)${scale !== 1 ? ` scale(${scale})` : ""
      }`;
}

export const generatePageLink = function (link: ThemeLink) {
  if (link.type === THEME_LINK_TYPE.PAGE_LINK)
    return getRouterLink(link.page_link);
  else if (link.type === THEME_LINK_TYPE.THEME_LINK)
    if (link.theme_link === THEME_LINKS.HOME_PAGE) return "/";
  if (link.theme_link === THEME_LINKS.ALL_ARTICLES) return "/posts/";
  console.warn(`no link type for ${link.type}}`);
  return "";
}

export const arrayContainsObject = (array: object[], object: object) => {
  return array.some((item) =>
    Object.keys(item).every((key) => item[key] === object[key])
  );
};

// A cross-browser safe way to convert wordpress post/page date to usable output date.
export const wordpressDateToString = (date: string): string => {
  const output = new Date(date.replace(/-/g, "/"));
  return output ? output.toLocaleDateString("en-UK") : '';
}

/**
 * Extracts the HTML elements from a given array of Vue refs.
 *
 * @param {Array} refs - An array of Vue refs or an array of arrays of Vue refs.
 * @returns {HTMLElement[]} - An array of HTML elements.
 *
 * @example
 * // Usage:
 * const elements = arrayOfElements([ref1, ref2]);
 * // or
 * const elements = arrayOfElements([[ref1, ref2], [ref3, ref4]]);
 */
export const arrayOfElements = function (refs) {
  // Ensure refs is an array, if not, return an empty array
  if (!Array.isArray(refs)) {
    console.error('Input should be an array of refs');
    return [];
  }

  // Flatten the array if it contains sub-arrays
  const flatRefs = refs.flat();

  // Map through the refs and get the HTML element from each ref
  const elements = flatRefs.reduce((acc, ref) => {
    // Check if ref is defined and is an instance of Vue
    if (ref && ref.$el) {
      acc.push(ref.$el);
    }
    // Check if ref is an instance of HTMLElement
    else if (ref instanceof HTMLElement) {
      acc.push(ref);
    }
    // Ignore if ref is undefined or not an instance of Vue or HTMLElement
    return acc;
  }, []);

  return elements;
}

export const deepEqual = (obj1: any, obj2: any): boolean => {
  if (obj1 === obj2) {
    return true;
  }
  if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 == null || obj2 == null) {
    return false;
  }
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
}

