
import { createModifierClass } from "@app/boilerplate/src/utils";
import { Vue, Component, Watch, Ref, Prop } from "vue-property-decorator";
import { SmartSVG, SVGLibraryCustom } from "_components";
import { GSAP } from "_core";

@Component({
  name: "FooterEmail",
  components: { SmartSVG },
})
export default class FooterEmail extends Vue {
  @Prop({ default: false, type: Boolean }) isHero: boolean;
  @Prop({ default: false, type: Boolean }) animPhase1: boolean;
  @Prop({ default: false, type: Boolean }) animPhase2: boolean;
  @Ref() underline1: HTMLElement;
  @Ref() underline2: HTMLElement;
  @Ref() underline3: HTMLElement;
  @Ref() logo1Ref: SmartSVG;
  @Ref() logo2Ref: SmartSVG;
  @Ref() logo3Ref: SmartSVG;

  rootClass = "c-footer-email";
  SVGLibraryCustom = SVGLibraryCustom;

  tl: gsap.core.Timeline;
  isHovering = false;
  svgLoadCount = 0;

  @Watch("isHovering")
  handleSelectedChange(isHovering: boolean) {
    if (isHovering) {
      this.tl.play();
    } else {
      this.tl.reverse();
    }
  }

  get wrapperClasses() {
    return [
      this.rootClass,
      ...createModifierClass(this.rootClass, this.isHero, "is-hero"),
      ...createModifierClass(
        this.rootClass,
        this.isHero && this.animPhase1,
        "blend-invert"
      ),
      ...createModifierClass(
        this.rootClass,
        this.isHero && this.animPhase2,
        "blend-invert"
      ),
    ];
  }

  get disablePointer() {
    if (!this.animPhase1) return {};
    return {
      pointerEvents: "none",
    };
  }

  get lowerLineStyles() {
    if (!this.animPhase1) return {};
    return {
      visibility: "hidden",
      pointerEvents: "none",
    };
  }

  mounted() {
    const ease = "circ.out";
    const duration = 0.5;
    this.tl = GSAP.timeline({ paused: true })
      .fromTo(this.underline1, { scaleX: 0 }, { scaleX: 1, ease, duration }, 0)
      .fromTo(
        this.underline2,
        { scaleX: 0 },
        { scaleX: 1, ease, duration },
        0.2
      )
      .fromTo(
        this.underline3,
        { scaleX: 0 },
        { scaleX: 1, ease, duration },
        0.3
      );
  }

  public getAnimationData(): {
    logo1: DOMRect;
    logo2: DOMRect;
    logo3: DOMRect;
  } {
    return {
      logo1: (this.logo1Ref.$el as HTMLElement).getBoundingClientRect(),
      logo2: (this.logo2Ref.$el as HTMLElement).getBoundingClientRect(),
      logo3: (this.logo3Ref.$el as HTMLElement).getBoundingClientRect(),
    };
  }

  handleSVGLoad() {
    this.svgLoadCount += 1;
    if (this.svgLoadCount === 4) {
      this.$nextTick(() => {
        this.$emit("loaded"); // Emitting the custom event
      });
    }
  }
}
