
import { Component, Mixins, Prop, Watch, Ref } from "vue-property-decorator";
import { SmartText } from "_components";
import { onScroll, onResize } from "_mixins";
import { createModifierClass, generateID } from "_utils";
import { EventBus, EventBusChannelsThemeSpecific, EventBusChannels, GSAP } from "_core";
import { SideBarProps } from "./types";

@Component({
  name: "SidebarComponent",
  components: { SmartText },
})
export default class SidebarComponent extends Mixins(onScroll, onResize) {
  @Prop() data: SideBarProps;
  @Prop({ default: false, type: Boolean }) isHome: boolean;

  @Ref() wrapperRef: HTMLElement;
  @Ref() buttonRef: HTMLElement[];

  rootClass = "c-sidebar";
  currentlySelected = 0;
  lastScroll = 0;
  counter = 0;
  isScrolling = false;
  scrollTimeout: number | null = null;
  isVisible = true;
  isShorterScreenHeight = false;

  @Watch("data")
  handleDataChange() {
    this.isVisible = true;
    this.$nextTick(() => {
      this.animIn();
    });
  }

  get ID() {
    return generateID();
  }

  get wrapperStyle() {
    return this.isVisible ? { opacity: 0 } : {};
  }

  get spacerClasses() {
    const root = `${this.rootClass}__spacer`;
    return [
      root,
      ...createModifierClass(
        root,
        this.isHome && this.lastScroll === 0,
        "expanded"
      ),
    ];
  }

  onScroll(scroll: number) {
    this.lastScroll = scroll;

    if (this.isScrolling) return;

    // Check if the scroll is past the fakeStart of CONTACT section
    const contactBlock = this.data.find((block) =>
      block.label.includes("CONTACT")
    );

    if (
      contactBlock &&
      contactBlock.fakeStart !== undefined &&
      scroll >= contactBlock.fakeStart
    ) {
      this.currentlySelected = this.data.indexOf(contactBlock);
      return;
    }

    for (let i = 0; i < this.data.length; i++) {
      const currentBlock = this.data[i];
      const nextBlock = this.data[i + 1] || { start: Infinity };

      if (scroll >= currentBlock.start && scroll < nextBlock.start) {
        this.currentlySelected = i;
        break;
      }
    }
  }

  blockClass(index: number) {
    const root = `${this.rootClass}__block`;
    return [
      root,
      ...createModifierClass(root, index === this.currentlySelected, "active"),
    ];
  }

  handleClick(index: number) {
    this.counter += 1;
    this.currentlySelected = index; // Update currentlySelected immediately
    this.isScrolling = true; // Set isScrolling to true

    if (this.scrollTimeout !== null) {
      // If there's a previous timeout, clear it
      clearTimeout(this.scrollTimeout);
    }


    window.scrollTo({
      top: this.data[index].start,
      behavior: "smooth",
    });

    setTimeout(() => {
      this.isScrolling = false;
    }, 1000);
  }

  getLabelText(label, index) {
    // If on mobile and the block is not currently selected, return first 3 characters
    if ((this.isShorterScreenHeight || this.isMob) && index !== this.currentlySelected) {
      return label.substring(0, 3);
    }
    return label;
  }

  animIn() {
    const tl = GSAP.timeline({ clearProps: "all" });

    // Choose properties based on whether it's mobile or not
    const translateProperty = this.isMob ? "y" : "x";
    const initialTranslateValue = this.isMob ? "100%" : "-100%";
    const borderProperty = this.isMob ? "borderTop" : "borderRight";

    tl.set(this.wrapperRef, {
      opacity: 1,
      [translateProperty]: initialTranslateValue,
    });
    tl.set(this.buttonRef, { [translateProperty]: initialTranslateValue });

    tl.to(this.wrapperRef, { [translateProperty]: 0 });
    // Staggered animation for each button
    this.buttonRef.forEach((button) => {
      tl.to(
        button,
        {
          [translateProperty]: 0,
          duration: 0.4,
          onStart: () => {
            // Add grey border at the start of the button's animation
            GSAP.set(button, { [borderProperty]: "1px solid #767676" });
          },
          onComplete: () => {
            // Remove the border once the button is in position
            GSAP.set(button, { [borderProperty]: "" });
          },
        },
        `-=0.35`
      ); // Adjust timing for staggering effect
    });

    EventBus.$once(EventBusChannels.ChangingPage, () => {
      this.buttonRef.reverse().forEach((button) => {
        tl.to(
          button,
          {
            [translateProperty]: initialTranslateValue,
            duration: 0.3,
            onStart: () => {
              // Add grey border at the start of the button's animation
              GSAP.set(button, { [borderProperty]: "1px solid #767676" });
            },
            onComplete: () => {
              // Remove the border once the button is in position
              GSAP.set(button, { [borderProperty]: "" });
            },
          },
          `-=0.18`
        ); // Adjust timing for staggering effect
      });
      tl.to(
        this.wrapperRef,
        { [translateProperty]: initialTranslateValue, duration: 0.3 },
        "-=0.2"
      );
    });
  }

  checkScreenHeight() {
    this.isShorterScreenHeight = window?.innerHeight <= 800;
  }

  onResize(): void {
    this.checkScreenHeight();
  }

  created() {
    EventBus.$emit(EventBusChannelsThemeSpecific.SidebarDetected);
  }

  mounted() {
    this.checkScreenHeight()
  }

  destroyed() {
    EventBus.$emit(EventBusChannelsThemeSpecific.SidebarHidden);
  }
}
