
import { Component, Prop, Mixins, Watch, Ref } from "vue-property-decorator";
import { getSearch, onScroll } from "_mixins";
import {
  EventBus,
  EventBusChannelsThemeSpecific,
  EventBusChannels,
  Locale,
  GSAP,
} from "_core";
import { SmartText, SmartSVG, SVGLibraryCustom } from "_components";
import { SVGMorph } from "../components";
import {
  MainSettings,
  SearchResult,
  EMPTY_SEARCH,
  RouterPageNames,
} from "_types";
import CTAButton from "../CTAButton/index.vue";
import { createModifierClass } from "_utils";
import { THEME_LINKS } from "_types";

@Component({
  components: { SmartText, SmartSVG, SVGLibraryCustom, CTAButton, SVGMorph },
})
export default class Header extends Mixins(getSearch, onScroll) {
  @Prop({ required: true }) settings: MainSettings;
  @Ref() mobileLinksRef: HTMLElement[];
  @Ref() ctaButtonRef: CTAButton;
  @Ref() ctaButtonDesktopRef: CTAButton;
  @Ref() wrapperRef: HTMLElement;
  @Ref() lineRef: HTMLElement;
  @Ref() linksRef: HTMLElement[];
  @Ref() logoRef: HTMLElement;

  query: string = "";
  results = EMPTY_SEARCH;
  rootClass = "c-header";
  SVGLibraryCustom = SVGLibraryCustom;

  measurements;
  scroll = 0;
  isNearBottom = false;
  THEME_LINKS = THEME_LINKS;

  isMenuOpen = false;
  hasSidebar = false;
  showLinks = false;

  tl: gsap.core.Timeline;

  @Watch("query")
  runSearch() {
    this.search(this.query);
  }

  @Watch("$route", { immediate: true, deep: true })
  onRouteChange(value) {
    this.isMenuOpen = false;
  }

  onSearchLoad(data: SearchResult) {
    this.results = data;
    // if (data.posts.length) console.log(data.posts);
  }

  get classes() {
    return [
      this.rootClass,
      createModifierClass(this.rootClass, this.isFixed, "is-fixed"),
      createModifierClass(this.rootClass, this.isNearBottom, "is-near-bottom"),
      createModifierClass(this.rootClass, this.isMenuOpen, "is-menu-open"),
      createModifierClass(this.rootClass, this.hasSidebar, "has-sidebar"),
      createModifierClass(this.rootClass, this.showLinks, "show-links"),
    ];
  }

  get headerLinks() {
    return this.settings.header && this.settings.header.header_links
      ? this.settings.header.header_links
      : [];
  }

  get ctaLink() {
    return this.settings.header?.link;
  }

  get languages() {
    return Locale.availableLanguages ? Locale.availableLanguages : [];
  }

  get enableLangSelector() {
    return Locale.isEnabled;
  }

  get isFixed() {
    if (this.$route.name !== RouterPageNames.HOME) return true;
    else return this.scroll > 0;
  }

  get headerIcon() {
    return {
      icons: [SVGLibraryCustom.SBHLogoFullAltAnim, SVGLibraryCustom.SBHLogoAlt],
      selected: this.isFixed ? 0 : 1,
    };
  }

  get isHome() {
    return this.$route.name === RouterPageNames.HOME
  }

  toggleMenu() {
    if (!this.isMenuOpen) {
      this.isMenuOpen = true;
      this.tl = GSAP.timeline();
      this.tl.fromTo(
        [...this.mobileLinksRef, this.ctaButtonRef.$el],
        { y: 30, opacity: 0 },
        {
          y: 0,
          opacity: 1,
          duration: 0.25,
          stagger: 0.15,
          delay: 0.25,
          clearProps: true,
        }
      );
    } else {
      this.tl = GSAP.timeline();
      this.tl.fromTo(
        [...this.mobileLinksRef, this.ctaButtonRef.$el].reverse(),
        { y: 0, opacity: 1 },
        {
          y: 30,
          opacity: 0,
          duration: 0.25,
          stagger: 0.15,
          // clearProps: true,
          onComplete: () => (this.isMenuOpen = false),
        }
      );
    }
  }

  onScroll(scroll: number) {
    this.scroll = scroll;

    if (!this.measurements) return;

    if (scroll > this.measurements.showInvert) {
      this.isNearBottom = true;
    } else {
      this.isNearBottom = false;
    }
  }

  switchLocale(locale: string) {
    Locale.switchLocale(locale, this.$route);
  }

  mounted() {
    this.initListeners();
    this.animateLine();
  }

  scrollToId(id: string) {
    const element: HTMLElement | null = document.getElementById(id);

    if (element) {
      const offset = element.offsetTop - 50;
      window.scrollTo({ top: offset, behavior: "smooth" });
    }

    if (this.isMenuOpen) this.isMenuOpen = false;
  }

  private initListeners() {
    EventBus.$on(EventBusChannelsThemeSpecific.FooterPosition, this.calc);
  }

  private calc(showInvert: number) {
    this.measurements = {
      showInvert: showInvert - 60,
    };
  }

  private animateLine() {
    this.tl = GSAP.timeline();
    this.tl.fromTo(
      this.lineRef,
      { x: -window.innerWidth * 2 },
      {
        x: 0,
        duration: 1,
        // clearProps: true,
        onComplete: () => (this.isMenuOpen = false),
      }
    );
  }

  handleFontLoad() {
    this.$nextTick(() => {
      this.showLinks = true;
      this.tl = GSAP.timeline();
      this.tl.fromTo(
        [...this.linksRef, this.ctaButtonDesktopRef.$el],
        { y: '-250%', },
        {
          y: 0,
          duration: 0.75,
          stagger: 0.15,
          ease: 'ease.circOut',
          clearProps: true,
        }
      );
    });
  }

  handleSVGLoad() {
    // this.$nextTick(() => {
    //   this.tl = GSAP.timeline()
    //   this.tl.fromTo(
    //     this.logoRef,
    //     { y: 10 },
    //     {
    //       y: 0,
    //       duration: 0.75,
    //       stagger: 0.15,
    //       clearProps: true,
    //     }
    //   );
    // })
  }

  hasSidebarCheck() {
    // if (window && window.innerWidth >= 768) {
    this.hasSidebar = true;
    // } else {
    // this.hasSidebar = false;
    // }
  }

  handleLogoClick() {
    if(this.isHome) window.scrollTo({ top: 0, behavior: "smooth" });
  }

  created() {
    if (this.isHome) this.hasSidebar = true;

    EventBus.$on(EventBusChannelsThemeSpecific.SidebarDetected, () => {
      this.hasSidebar = true;
    });

    EventBus.$on(EventBusChannelsThemeSpecific.SidebarHidden, () => {
      this.hasSidebar = false;
    });

    if (document.fonts.status === "loading") {
      EventBus.$once(EventBusChannels.FontsLoaded, () => {
        this.handleFontLoad();
      });
    } else {
      this.handleFontLoad();
    }
  }
}
