
import { MainSettings } from "_types";
import { Vue, Component, Prop } from "vue-property-decorator";
import { SmartText } from "_components";
import { EventBus, EventBusChannelsThemeSpecific } from "_core";
import LinkButton from "../LinkButton/index.vue";
import FooterEmail from "../FooterEmail/index.vue";
import { createModifierClass } from "@app/boilerplate/src/utils";

@Component({
  name: "Footer",
  components: {
    SmartText,
    LinkButton,
    FooterEmail,
  },
})
export default class Footer extends Vue {
  @Prop({ required: true }) settings: MainSettings;
  @Prop({ default: false, type: Boolean }) isHero: boolean;
  @Prop({ default: false, type: Boolean }) hasSidebar: boolean;

  rootClass = "c-footer";

  public offsetTop() {
    const offset = (this.$el as HTMLElement).offsetTop;
    EventBus.$emit(EventBusChannelsThemeSpecific.FooterPosition, offset);
    return offset;
  }

  get wrapperClasses() {
    return [
      this.rootClass,
      ...createModifierClass(this.rootClass, this.hasSidebar, "has-sidebar"),
    ];
  }

  get introText() {
    return this.settings?.footer?.intro_text;
  }

  get links() {
    return this.settings?.footer?.links;
  }

  get socialLinks() {
    return this.settings?.footer?.social_links;
  }

  get copyrightText() {
    return this.settings?.footer?.copyright_text;
  }
}
