/*
    Add SVGs to be accessible to CL and SmartSVG by simply
    adding it to the ./library folder and adding an enum
    matching the file name below.
*/

export enum SVGLibraryCustom {
  BoilerplateBackButton = "boilerplate-back-button",
  BoilerplateClose = "boilerplate-close",
  BoilerplateCopy = "boilerplate-copy",
  BoilerplateError = "boilerplate-error",
  LhLogo = "lh-logo",
  SBHLogo = "sbh-logo",
  SBHLogoAlt = 'sbh-logo-alt',
  SBHLogoFull = "sbh-logo-full",
  SBHLogoFullAlt = "sbh-logo-full-alt",
  SBHLogoFullAltAnim = "sbh-logo-full-alt-anim",
  SBHArrowRight = "sbh-arrow-right",
  SBHVariant = "sbh-variant",
  SBHIcon1 = 'sbh-icon-1',
  SBHIcon2 = 'sbh-icon-2',
  SBHIcon3 = 'sbh-icon-3',
  SBHIcon4 = 'sbh-icon-4',
  SBHIcon5 = 'sbh-icon-5',
  SBHIcon6 = 'sbh-icon-6',
  SBHIcon7 = 'sbh-icon-7',
  SBHIcon8 = 'sbh-icon-8',
  SBHIcon9 = 'sbh-icon-9',
  // SBHIcon9Alt = 'sbh-icon-9-alt',
  SBHIcon10 = 'sbh-icon-10',
  SBHIcon11 = 'sbh-icon-11',
  SBHIcon12 = 'sbh-icon-12',
  SBHIcon13 = 'sbh-icon-13',
  SBHIcon14 = 'sbh-icon-14',
  SBHIcon15 = 'sbh-icon-15',
  SBHIcon16 = 'sbh-icon-16',
  SBHIcon17 = 'sbh-icon-17',
  SBHIcon18 = 'sbh-icon-18',
  SBHIcon19 = 'sbh-icon-19',
  SBHIcon20 = 'sbh-icon-20',

  SBHPlus = 'sbh-plus',

  SBHClose = 'sbh-close',
  EmailLogoAmp = 'email-logo-amp',
  EmailLogo1 = 'email-logo-1',
  EmailLogo2 = 'email-logo-2',
  EmailLogo3 = 'email-logo-3',
  SBHBurgerMenu = 'sbh-burger-menu',
  SBHSliderArrowRight = 'sbh-slider-arrow-right',
  SBHSliderArrowLeft = 'sbh-slider-arrow-left'
}
